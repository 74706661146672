.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vh;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  opacity: 1;

  &.fade-out {
    opacity: 0;
    pointer-events: none;
  }

  .content-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .loading-icon {
      text-align: center;
      font-size: 25rem;
      animation: rotate 3s linear infinite;
    }
  }
}
.loader {
  border: 10px solid rgb(255, 244, 3);
  border-radius: 50%;
  border-top: 10px solid #3498db;
  border-bottom: 10px solid #FFC107;
  width: 150px;
  height: 150px;
  left: 43.5%;
  top: 40%;
  position: fixed;
  animation: rotate 1.5s linear infinite forwards;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.image-loader {
  width: 100px;
  height: 150px;
  left: 45.5%;
  top: 42%;
  position: fixed;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
