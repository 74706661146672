.card {
  border: black 2px solid;
  height: 100vh;
  margin: 10px;

  .card-title {
    margin: 10px;
    font-size: 16px;
    font-weight: bolder;
  }

  .card-body {

  }
}
