.buy-quota {
  margin: 10px;

  .add-quota {
    display: flex;
    justify-content: center;

    .quota {
      border: black 1px solid;
      font-size: 48px;
      padding: 0 20px;
      border-radius: 15px;
    }

    .button-add-quota {
      padding: 0 20px;
      cursor: pointer;
      font-weight: bolder;
      font-size: 48px;
    }
  }
}

.button-quota {
  margin-top: 20px;
  background-color: #0f74f8;
  padding: 10px 0;
  border-radius: 15px;
  color: white;
  cursor: pointer;
}

.button-quota-disabled {
  margin-top: 20px;
  background-color: rgba(173, 173, 173, 0.75);
  padding: 10px 0;
  border-radius: 15px;
  color: white;
  cursor: progress;
}

.message {
  color: red;
  font-style: italic;
  padding: 20px;

  p {
    margin: 0;
    padding: 0;
  }
}

.bottom-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;

    .image {
      img {
        width: 100%;
        height: 100%;
      }
    }

    .content-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 20px;
      background-color: #ffffff;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      border-radius: 10px;

      div {
        &:first-child {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        &:nth-child(2) {
          font-size: 18px;
          margin-bottom: 10px;
        }

        &:last-child {
          font-size: 16px;
          color: #999999;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .bottom-content {
    padding: 10px;

    .content {
      flex-direction: column;
      align-items: center;

      .image {
        img {
          width: 100%;
          height: 100%;
        }
      }

      .content-right {
        padding: 10px;
        margin-top: 20px;

        div {
          &:first-child {
            font-size: 18px;
            margin-bottom: 5px;
          }

          &:nth-child(2) {
            font-size: 16px;
            margin-bottom: 5px;
          }

          &:last-child {
            font-size: 14px;
            color: #999999;
          }
        }
      }
    }
  }
}

.qr-code {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.notification {
  padding: 10px;
  color: #fff3cd;
  box-shadow: 5px 3px 5px 0 #888;
}
