.about {
  background-image: url("../../../image/waves.svg");
  background-repeat: no-repeat;
  background-size: 100% 200px;

  .header {
    text-align: center;
    font-size: 36px;
    font-weight: bolder;
  }

  .sub-header {
    margin: 10px;
    text-align: center;

    p {
      font-style: italic;
    }
  }

  .content {
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .title {
    margin-top: 20px;
  }

  .costume-text {
    text-justify: inter-word;
    line-height: 30px;
  }

  .content-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .custom-list {
    list-style-type: decimal;
  }

  .custom-bullet-list {
    list-style-type: disc;
  }

}
