@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
  scroll-behavior: smooth;
  font-family: 'Raleway', sans-serif;
}

@layer base {
  body {
    @apply bg-[#ecf0f3] text-[#1f2937] tracking-wide
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-bold
  }

  h1 {
    @apply text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-[Raleway]
  }

  h2 {
    @apply text-2xl sm:text-3xl md:text-4xl lg:text-5xl
  }

  h3 {
    @apply text-xl sm:text-2xl md:text-3xl lg:text-4xl
  }

  h4 {
    @apply text-base sm:text-xl md:text-2xl lg:text-3xl
  }

  li {
    @apply cursor-pointer
  }

  button {
    @apply shadow-xl shadow-gray-400 rounded-xl uppercase bg-gradient-to-r from-[#5651e5] to-[#709dff] text-white
  }
}
