#features {
  background-image: url("../../../image/waves.svg");
  background-repeat: no-repeat;
  background-size: 100% 100px;
  margin-bottom: 50px;

  .header {
    text-align: center;
    font-size: 36px;
    font-weight: bolder;
  }

  .sub-header {
    text-align: center;
    font-style: italic;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 10vh;
  }

  .content {
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .content-item {
      display: flex;
      align-items: center;
      margin: 10px;
      padding: 20px;

      .content-icon {
        color: #0f74f8;
      }

      .content-detail {
        margin: 10px;

        .content-detail-title {
          font-weight: bolder;

        }

        .content-detail-description {
          font-style: italic;
        }
      }
    }
  }
}
