.react-pdf__Page {
  //margin: 20px;
  /*top:0px;*/
  //box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); /* Efek bayangan bisa disesuaikan */
}

.view-pdf {
  position: absolute;
  border: 1px #000000 solid;
  padding: 0;
  margin: 0;
}

.signature-container {
  position: relative;
  margin-top: 30px;
  height: 100vh;

  .signature-container-content {
    position: absolute;
    margin-top: 30px;
    height: 100vh;

    .signature-image {
      position: absolute;
      cursor: crosshair;
      //user-select: none;
      /* Style untuk gambar tanda tangan */
      /* Kamu dapat menyesuaikan dengan ukuran dan tata letak yang diinginkan */
    }

    .signature-container-header {
      background-color: rgba(173, 173, 173, 0.75);
      border: 1px rgba(173, 173, 173, 0.75) solid;
      display: flex;
      padding: 5px;
      justify-content: space-between;

      .signature-container-header-content {
        display: flex;
        align-items: center;

        .button-page {
          background-color: #4f4f4f;
          padding: 5px 15px;
          color: white;
          border-radius: 5px;
          margin: 0 5px;
        }

        .button-page:hover {
          cursor: pointer;
          background-color: white;
          color: #4f4f4f;
        }
      }

    }
  }
}

.pdf-container {
  /* Style untuk area file PDF */
  /*width: 100%; !* Sesuaikan dengan lebar file PDF *!*/
  /*height: 500px; !* Sesuaikan dengan tinggi file PDF *!*/
  border: 1px solid #ccc; /* Contoh border untuk visualisasi area PDF */
  position: absolute; /* Pastikan area PDF memiliki posisi relatif */
}


.react-pdf__Page .react-pdf__Page__annotations {
  position: absolute;
  display: none;
}

.react-pdf__Page .react-pdf__Page__textContent {
  display: none;
}

.notification-ttd-electronic{
  background-color: rgba(0, 255, 0, 0.56);
  padding: 9px 15px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}
.EmbedDocument {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px;

  .btn-download-document {
    background-color: #f60404;
    padding: 9px 15px;
    width: 150px;
    text-align: center;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }

  .btn-download-document:hover {
    background-color: rgb(0, 255, 0);
    color: black;
  }

  .btn-continue-sign {
    background-color: #0f74f8;
    padding: 9px 15px;
    width: 200px;
    text-align: center;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }

  .btn-continue-sign:hover{
    background-color: rgb(255, 244, 3);
    color: black;
  }
}
