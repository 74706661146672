#home {
  position: relative;
  width: 100%;
  padding: 20vh 0;
  background-image: url('../../../image/triangleAsymmetricaledit.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;

  .content-home {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .content-item-home {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      //border: 1px black solid;
      //width: 40%;

      //@include respond-to(medium) {
      //  width: 40%;
      //}


      .content-home-logo {
        padding: 10px;

        img {
          width: 300px;
        }
      }

    }
  }
}

.brand{
  display: flex;
  justify-content: center;
  align-items: center;

  span{
    font-weight: bolder;
    font-size: x-large;
  }
}
