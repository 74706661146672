.not-found-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0 20px;

  h1 {
    font-size: 3rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1.2rem;
    color: #666;
    text-align: center;
    margin-bottom: 2rem;
  }

  .button {
    background-color: #0f74f8;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }

  .button:hover {
    background-color: #3e8e41;
  }

  a {
    text-decoration: none;
    color: white;
  }
}
