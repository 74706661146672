#contact {
  background-image: url("../../../image/triangleAsymmetrical.svg");
  background-position: bottom;

  .header {
    text-align: center;
    font-size: 36px;
    font-weight: bolder;
  }

  .sub-header {
    text-align: center;
    font-style: italic;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 10vh;
  }

  .content-contact {
    display: flex;
    justify-content: space-around;
    margin: 10px;

    .content-item-contact {
      margin: 10px;
      border-radius: 15px;
      background-color: rgba(255, 255, 255, 0.35);
      //padding: 10px 0;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7);
      border: 1px solid rgba(173, 173, 173, 0.75);

      h2 {
        text-align: center;
      }

      .content-form {

        .form-group {
          margin: 10px 0;
        }

        .btn-submit {
          background-color: #0f74f8;
          padding: 10px;
          border-radius: 15px;
          text-align: center;
          color: white;
          cursor: pointer;
        }
      }

      .contact-info {
        display: flex;

        .contact-info-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 10px;
        }

        .contact-info-detail {
          margin: 10px;
        }
      }

    }
  }
}

